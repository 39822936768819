<div class="insight-wrapper">
  <div class="row">
    <div class="col-12 text-center">
      <h1 class="harmony-secondary-text fw-bold">
        News & Insights
      </h1>
    </div>
  </div>
  <!-- Dynamic list of all recent articles -->
  <div class="row mt-3">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="row" *ngIf="articles && articles.length > 0; else noarticles">
        <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let article of articles; let i = index">
          <app-article-item [Article]="article" [Class]="i % 2 === 0 ? 'headline-odd' : 'headline-odd'"></app-article-item>
        </div>
      </div>
      <ng-template #noarticles>
        <div class="row my-5">
          <div class="col-12">
            <app-loader></app-loader>
          </div>
        </div>
      </ng-template>
      <div class="row mb-5 mt-3">
        <div class="col-12 text-center">
          <p class="load-more-text" (click)="loadMoreArticles()" *ngIf="showMore">More</p>
        </div>
      </div>
    </div>
  </div>
</div>
