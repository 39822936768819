<div class="article-card pointer" (click)="readArticle()" *ngIf="article">
    <div class="article-headline"
         [ngStyle]="{ backgroundImage: article.ImageReference ?
       'url(\'' + environment.SiteUrls.AzureStorageBaseURL + environment.ContainerNames.ArticleImages + '/' + article.Id + '/' + article.ImageReference + '\')' :
       'url(\'https:\/\/hrn-ui.azurewebsites.net/assets/branding/HRN_header_idmanual.jpg\')' }">
        <p class="article-headline-text" [ngClass]="Class">
            {{ article.Title }}
        </p>
    </div>
    <div class="article-body">
        <div class="row">
            <div class="col-12 text-center">
                <p class="article-publish-date">
                    {{ article.PublishDate | date: env.FormattingStandards.ShortDateFormat }} -
                    <b class="harmony-accent-coral-text fw-light">{{ article.Author ?? '' }}</b>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="article-content mt-2 mb-2" [innerHTML]="article.Summary">
                    <a class="read-more-link" (click)="readArticle()">Read article</a>
                </p>
            </div>
        </div>
    </div>
</div>
