
<header class="corporate-header p-3">
  <div class="logo-section">
    <a class="corporate-header-logo text-dark" [routerLink]="['']">
      <img src="../../../assets/branding/logo/HRN_logo_RGB.png"
           class="pointer"
           alt="Harmony Relocation Network"
           style="width: 95%;"
           (click)="router.navigate([''])"/>
    </a>
  </div>
  <div class="nav-wrapper">
    <div class="row">
      <div class="col-12 text-start">
        <nav class="nav d-flex justify-content-end">
          <a class="p-2 upper-link" [routerLink]="['/joining-our-network']">
            Join our network
          </a>
          <a class="p-2 upper-link" [routerLink]="['/direct-quote']">
            Direct quote
          </a>
          <a class="p-2 upper-link"
             href="https://intranet.harmonyrelo.com/"
             [title]="'Go to the Intranet'"
             target="_blank">
            Intranet
          </a>
          <a class="btn btn-sm harmony-accent-coral-button text-white ms-2"
             [title]="'Log in to the Harmony PORTAL'"
             (click)="goToPortal()"
             style="padding-top: 0.4rem;">
            Member Login
          </a>
        </nav>
      </div>
    </div>
    <div class="row main-menu-row">
      <div class="col-9">
        <nav class="nav d-flex justify-content-start float-start ms-3">
          <a class="p-2 me-2 secondary-link" [routerLink]="['']">
            Home
          </a>
          <div class="dropdown">
            <button class="btn dropdown-toggle header-btn secondary-link"
                    type="button"
                    id="about-us-menu"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
              About us
            </button>
            <ul class="dropdown-menu" aria-labelledby="about-us-menu">
              <li>
                <button class="dropdown-item secondary-link" type="button" [routerLink]="['/about-us/history']">History</button>
              </li>
              <li>
                <button class="dropdown-item secondary-link" type="button" [routerLink]="['/about-us/clients']">Clients</button>
              </li>
              <li>
                <button class="dropdown-item secondary-link" type="button" [routerLink]="['/about-us/services']">Services</button>
              </li>
              <li>
                <button class="dropdown-item secondary-link" type="button" [routerLink]="['/about-us/people']">People</button>
              </li>
              <li>
                <button class="dropdown-item secondary-link" type="button" [routerLink]="['/about-us/commitment']">Commitment</button>
              </li>
            </ul>
          </div>
          <a class="p-2 me-2 secondary-link" [routerLink]="['/global-network']">Global network</a>
          <a class="p-2 me-2 secondary-link" [routerLink]="['/business-solutions']">Business Solutions</a>
          <a class="p-2 me-2 secondary-link" [routerLink]="['/insights']">News & Insights</a>
          <a class="p-2 me-2 secondary-link" [routerLink]="['/team']">Meet the team</a>
          <a class="p-2 me-2 secondary-link" [routerLink]="['/contact']">Contact</a>
        </nav>
      </div>
      <div class="col-3">
        <app-search></app-search>
      </div>
    </div>
  </div>
</header>
