import {Component, OnDestroy, OnInit} from '@angular/core';
import { IArticleOverview, IArticleSearchParam } from '../../services/models/articles.model';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ArticleItemComponent} from './article-item/article-item.component';
import {LoaderComponent} from '../../shared/components/loader/loader.component';
import {NgForOf, NgIf} from '@angular/common';
import {ArticleService} from "../../services/article.service";
import {IPage, IPaginationData} from '../../shared/models/pagination-data.model';
import {IOrderParam} from "../../shared/directives/order/order.directive";

@Component({
  selector: 'app-insights',
  standalone: true,
  templateUrl: './insights.component.html',
  imports: [
      ArticleItemComponent,
      LoaderComponent,
      NgForOf,
      NgIf
  ],
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit, OnDestroy {

  // Component Variables
  articles: IArticleOverview[] = [];
  loading: boolean = false;
  page: IPage = {
      batched: false,
      pageNumber: 1,
      pageSize: 15
  };
  order: IOrderParam = {
      OrderBy: 'PublishDate',
      OrderDirection: 'desc'
  }
  showMore: boolean = true;

  // General variables
  private unsubscribe: Subject<any> = new Subject<any>();
  environment = environment;

  constructor(private articleService: ArticleService) { }

  ngOnInit() {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      this.loadArticles();
      this.page = {
          batched: false,
          pageNumber: 1,
          pageSize: 15
      }
  }

  ngOnDestroy(): void {
      this.unsubscribe.next(null);
      this.unsubscribe.complete();
  }

  loadArticles() {
    this.loading = true;
    const param = {
      SearchText: null,
      Featured: null,
      Website: true,
      ActiveOnly: true,
      Status: 'Published',
      CompanyId: null,
      UserId: null
    } as IArticleSearchParam;

    this.articleService.GetArticles(this.page, this.order, param)
      .subscribe((data: IPaginationData<IArticleOverview>) => {
        if (data && data.DataSet) {
          this.articles = this.articles.concat(...data.DataSet);
          this.showMore = data.CurrentPage < data.TotalPages;
        }
        this.loading = false;
      });
  }

  loadMoreArticles() {
    this.page.pageNumber++
    this.loadArticles();
  }
}
