<div class="homepage-wrapper">
  <!-- START: Jumbotron -->
  <div class="corporate-jumbotron">
    <div class="jumbotron-video">
      <div class="video-container">
        <div *ngFor="let video of videos; let i = index">
          <video
            class="video"
            muted
            autoplay
            controls
            #videoPlayer
            *ngIf="i === videoIndex"
            (ended)="nextVideo()">
            <source [src]="environment.SiteUrls.AzureStorageBaseURL + video" type="video/mp4" />
            Browser not supported
          </video>
        </div>
      </div>
    </div>
    <img class="video-overlay-1 position-absolute" src="../../../assets/shapes/purple/hex-sm.svg" alt=""/>
    <img class="video-overlay-2 position-absolute" src="../../../assets/shapes/blue/long-hex-horizontal.svg" alt=""/>
    <img class="video-overlay-3 position-absolute" src="../../../assets/shapes/teal/long-hex-horizontal.svg" alt=""/>
    <img class="video-overlay-4 position-absolute" src="../../../assets/shapes/teal/short-hex-horizontal.svg" alt=""/>
    <img class="video-overlay-5 position-absolute" src="../../../assets/shapes/purple/hex-sm.svg" alt=""/>
    <div class="homepage-statement-container">
      <div class="row">
        <div class="col-10 offset-1 text-center pt-3">
          <span class="statement-text harmony-accent-sand-text">
            Harmony is a cooperative network of relocation companies.
            We provide <span class="statement-text harmony-primary-text">worldwide moving and relocation services</span>
            for corporate and private customers.
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 offset-md-4 offset-sm-3">
          <a class="btn btn-sm read-more harmony-tertiary-button text-white pointer" [routerLink]="['/about-us/services']">
            Read more
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Jumbotron -->

  <!-- START: Our Services -->
  <div class="row services-section-row">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="row">
        <!-- Clients -->
        <div class="col-md-3 col-sm-12">
          <div class="row">
            <div class="col-12 text-center card-icon">
              <a [routerLink]="['/about-us/clients']">
                <img class="" ngSrc="../../../assets/images/vector/house-shape-1.svg" width="150" height="160" alt=""/>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <h3 class="harmony-primary-text fw-bold">
                Our Clients
              </h3>
            </div>
          </div>
          <div class="row services-content">
            <div class="col-12 text-center">
              <p class="harmony-accent-grey-text">
                Taking care of you is our top priority
              </p>
            </div>
          </div>
        </div>
        <!-- Services -->
        <div class="col-md-3 col-sm-12">
          <div class="row">
            <div class="col-12 text-center card-icon">
              <a [routerLink]="['/about-us/services']">
                <img class="" ngSrc="../../../assets/images/vector/truck-shape-1.svg" width="150" height="160" alt=""/>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <h3 class="harmony-primary-text fw-bold">
                Our Services
              </h3>
            </div>
          </div>
          <div class="row services-content">
            <div class="col-12 text-center">
              <p class="harmony-accent-grey-text">
                Relocation is more than just moving people. It’s about moving people’s lives
              </p>
            </div>
          </div>
        </div>
        <!-- People -->
        <div class="col-md-3 col-sm-12">
          <div class="row">
            <div class="col-12 text-center card-icon">
              <a [routerLink]="['/about-us/people']">
                <img class="" ngSrc="../../../assets/images/vector/woman-shape-2.svg" width="150" height="160" alt=""/>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <h3 class="harmony-primary-text fw-bold">
                Our People
              </h3>
            </div>
          </div>
          <div class="row services-content">
            <div class="col-12 text-center">
              <p class="harmony-accent-grey-text">
                Local experts committed to making the difference between a good relocation and a successful new start
              </p>
            </div>
          </div>
        </div>
        <!-- Commitment -->
        <div class="col-md-3 col-sm-12">
          <div class="row">
            <div class="col-12 text-center card-icon">
              <a [routerLink]="['/about-us/commitment']">
                <img class="" ngSrc="../../../assets/images/vector/handshake-shape-1.svg" width="150" height="160" alt=""/>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <h3 class="harmony-primary-text fw-bold">
                Our Commitment
              </h3>
            </div>
          </div>
          <div class="row services-content">
            <div class="col-12 text-center">
              <p class="harmony-accent-grey-text">
                Delivering excellence in harmony with the world in which we operate
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Our Services -->

  <!-- START: Content 1 -->
  <div class="row mt-4 mx-sm-1">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="content-block">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <h1 class="content-block-heading harmony-primary-text">
              We combine local cultural identity with <span class="harmony-secondary-text">global coverage</span>
            </h1>
            <p class="fs-5 pe-3 pt-2 harmony-accent-grey-text">
              When relocating internationally, our coordinators are usually the last people our customers say goodbye to, and the first ones they say hello to.
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="content-block-image"
                 [ngStyle]="{ 'background-image': 'url(' + baseImageUrl + 'homepage-section-1.jpg' + ')'}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content 1 -->

  <!-- START: Global Network -->
  <div class="homepage-global-network">
    <app-global-network></app-global-network>
  </div>
  <!-- END: Global Network -->

  <!-- START: Articles -->
  <div class="row mt-4 mb-4 mx-sm-1" *ngIf="latestArticles && latestArticles[0]">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="content-block">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="content-block-image"
                 [ngStyle]="{ 'background-image':
                 latestArticles[0].ImageReference ?
                 'url(\'' + environment.SiteUrls.AzureStorageBaseURL + environment.ContainerNames.ArticleImages + '/' + latestArticles[0].Id + '/' + latestArticles[0].ImageReference + '\')' :
                 'url(' + baseImageUrl + 'homepage-section-1.jpg' + ')'}">
            </div>
          </div>
          <div class="col-md-6 col-sm-12 mt-sm-2">
            <h1 class="content-block-heading harmony-primary-text">
              {{ latestArticles[0].Title }}
            </h1>
            <p class="fs-5 harmony-accent-grey-text">
              {{ latestArticles[0].Summary }}
            </p>
            <button class="btn btn-primary harmony-accent-coral-button" *ngIf="latestArticles && latestArticles[0]"
                    [routerLink]="['insights/article/', latestArticles[0].Id]">Read Full
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2 mb-5 mx-sm-1" *ngIf="latestArticles && latestArticles[1]">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="content-block">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <h1 class="content-block-heading harmony-primary-text">
                {{ latestArticles[1].Title }}
            </h1>
            <p class="fs-5 harmony-accent-grey-text">
                {{ latestArticles[1].Summary }}
            </p>
            <button class="btn btn-primary harmony-accent-coral-button" *ngIf="latestArticles && latestArticles[1]"
                    [routerLink]="['insights/article/', latestArticles[1].Id]">Read Full
            </button>
          </div>
          <div class="col-md-6 col-sm-12 mt-sm-2">
              <div class="content-block-image"
                   [ngStyle]="{ 'background-image':
                    latestArticles[1].ImageReference ?
                       'url(\'' + environment.SiteUrls.AzureStorageBaseURL + environment.ContainerNames.ArticleImages + '/' + latestArticles[1].Id + '/' + latestArticles[1].ImageReference + '\')' :
                        'url(\'' + baseImageUrl + 'homepage-section-2.jpg' + ')'}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Articles -->
</div>
