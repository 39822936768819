<div class="commitment-wrapper">
  <div class="commitment-content">
    <div class="row">
      <div class="col-md-12">
        <h1 class="harmony-secondary-text fw-bold">
          Our Commitment to Sustainability
        </h1>
      </div>
    </div>
    <div class="row harmony-accent-grey-text">
      <div class="col-md-12">
        <p >
          We are committed to working in harmony with the environment we live in. All companies within the Harmony
          Relocation Network strive to take their corporate & social responsibilities seriously. Whilst we all focus on
          building our companies, we do that in a socially responsible, ethical and sustainable way. Our clients recognise
          this in everything we do, from our policies, the people we employ and the partners we work with.
        </p>
        <p>
          We uphold all global & local regulations on Health, Safety, Quality, Compliance, GDPR, Sustainability and Environment. We are committed to continuously developing futureproof standards and delivering them without compromise.
        </p>
        <p>
          Doing the right thing is second nature to us.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="commitment-images">
          <img class="commitment-image image-1" src="../../../../assets/shapes/teal/hex-sm.svg" alt=""/>
          <img class="commitment-image image-2" src="../../../../assets/shapes/purple/long-hex-horizontal.svg" alt=""/>
          <img class="commitment-image image-3" src="../../../../assets/images/shaped/long-horizontal-people-3.png" alt=""/>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <h1 class="harmony-secondary-text fw-bold">
          Compliance
        </h1>
      </div>
    </div>
    <div class="row harmony-accent-grey-text">
      <div class="col-md-12">
        <p>
          Our commitment to our staff, our customers, our work and sustainability has been laid down in the international
          standards with which we comply:
        </p>
        <div class="row mb-2">
          <div class="col-sm-6 col-md-3 text-center">
            <fa-icon class="iso-icon" size="4x" [icon]="faQuality"></fa-icon>
            <p class="mt-3">
              <span class="harmony-tertiary-label fs-5 fw-bold">ISO9001</span>
            </p>
            <p class="fw-bold harmony-secondary-text">Quality Management</p>
          </div>
          <div class="col-sm-6 col-md-3 text-center">
            <fa-icon class="iso-icon" size="4x" [icon]="faEnvironment"></fa-icon>
            <p class="mt-3">
              <span class="harmony-tertiary-label fs-5 fw-bold">ISO14001</span>
            </p>
            <p class="fw-bold harmony-secondary-text">Environmental Management</p>
          </div>
          <div class="col-sm-6 col-md-3 text-center">
            <fa-icon class="iso-icon" size="4x" [icon]="faHealthSafety"></fa-icon>
            <p class="mt-3">
              <span class="harmony-tertiary-label fs-5 fw-bold">ISO45001</span>
            </p>
            <p class="fw-bold harmony-secondary-text">Occupational Health & Safety Management</p>
          </div>
          <div class="col-sm-6 col-md-3 text-center">
            <a [href]="CodeOfConductDocumentUrl" target="_blank" class="harmony-secondary-link">
              <fa-icon class="iso-icon" size="4x" [icon]="faCode"></fa-icon>
              <p class="mt-3">
                <span class="harmony-tertiary-label fs-5 fw-bold">ISO26000</span>
              </p>
              <p class="fw-bold text-decoration-underline">
                Code of Conduct
              </p>
            </a>
          </div>
        </div>
        <p>
          All of our staff and network member companies have committed to Harmony’s Code of Conduct. This Code sets out our
          commitment to important topics such as Ethical Behaviour, Integrity, Sustainability, Anti-Bribery & Corruption,
          Anti-Competition, and GDPR. The Code states how you can expect our network, our companies and our employees to
          behave to ensure we always try our best to do the right thing. Our entire network follows annual, mandatory
          compliance training.
        </p>
      </div>
    </div>
  </div>
  <div class="mission-wrapper">
    <div class="mission-container">
      <p class="mission-header">
        Mission
      </p>
      <p class="mission-text">
        Harmony Relocation Network’s mission is to provide superior relocation services to customers around the world,
        using the combined skills and strengths of our independent member companies worldwide. We strive to do so in
        harmony with the world we operate in, and we operate ethically as a business. We consider the health and safety
        of our employees and customers, and the environmental impact of our work. We acknowledge the rights of workers
        to earn a fair wage, and we ensure that data privacy of our customers is respected at all times. In everything
        we do, we try to do the right thing.
        <br>
        <br>
        We challenge and support our network partners to continually improve their businesses and their services, with
        a constant focus on customer experience.
      </p>
    </div>
  </div>
</div>
