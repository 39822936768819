<div class="form-inline search-bar pull-right">
  <input
    class="form-control me-sm-2 search-input"
    type="search"
    placeholder="Search members..."
    aria-label="Search" [(ngModel)]="searchText"
    (focusin)="searchInFocus = true"
    (input)="onPrimaryMobileSearch()">
  <fa-icon class="fa-pull-right pointer search-icon" [icon]="faSearch"></fa-icon>
  <div class="search-results-mobile" *ngIf="showResults()" (mouseleave)="searchInFocus = false">
    <a class="text-decoration-none" *ngFor="let result of searchResults" [routerLink]="['member', result.CompanyId]">
      <div class="row search-result-row pointer">
        <div class="col-3 text-center">
          <img *ngIf="result.CompanyLogo" [src]="result.CompanyLogo" [alt]="null" class="search-result-logo">
          <fa-icon *ngIf="!result.CompanyLogo" class="search-result-logo harmony-primary-text" [size]="'2x'" [icon]="faNoLogo"></fa-icon>
        </div>
        <div class="col-9">
          <p class="search-result-name">
            {{ result.Name }}
          </p>
          <small class="harmony-accent-grey-text">
            {{ result.Country }}
          </small>
        </div>
      </div>
    </a>
  </div>
</div>
