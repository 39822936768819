import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ICompanySearchResult } from '../../services/models/member.model';
import { WebsiteService } from '../../services/website.service';
import { environment } from '../../../environments/environment';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgForOf, NgIf } from '@angular/common';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'app-search',
  standalone: true,
  templateUrl: './search.component.html',
  imports: [
    FormsModule,
    FaIconComponent,
    RouterLink,
    NgForOf,
    NgIf
  ],
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;

  // Icons
  faSearch = faSearch;

  // Component Variables
  searchText: string | null = null;
  searchResults: ICompanySearchResult[] | null = [];
  searchInFocus: boolean = false;
  searchText$: Subject<string | null> = new Subject<string | null>();

  // General variables
  env = environment;

  constructor(public router: Router,
              private websiteService: WebsiteService) { }

  ngAfterViewInit(){
    this.searchText$
        .pipe(debounceTime(500)) // before emitting last event
        .pipe(distinctUntilChanged())
        .subscribe(()=> {
          this.searchMembers()
        });
  }

  setValue() {
    this.searchText$.next(this.searchText);
  }

  searchMembers() {
    if (this.searchText && this.searchText.length > 0) {
      this.websiteService.SearchWebsite(this.searchText).subscribe((results: ICompanySearchResult[]) => {
        if (results && results.length) {
          this.searchResults = Object.assign([], results);
        } else {
          this.searchResults = null;
        }
      });
    } else {
      this.searchResults = null;
      this.searchText = null;
    }
  }

  showResults() {
    const hasResults = this.searchText && this.searchResults && this.searchResults.length > 0;
    return hasResults && this.searchInFocus;
  }
}
