<div class="client-wrapper">
  <div class="client-content">
    <div class="row">
      <div class="col-md-12">
        <h1 class="harmony-secondary-text fw-bold">
          Our Clients
        </h1>
      </div>
    </div>
    <div class="row harmony-accent-grey-text">
      <div class="col-md-12">
        <p>
          Flexibility is key. Our clients are private individuals, corporate businesses, governments, NGOs and Relocation
          Management Companies; we support customers by providing moving and relocation services in the way that best
          suits them.
        </p>
        <p>
          There is more than one way to manage a global relocation programme; For some insight, check out this
          <a [href]="env.SiteUrls.AzureStorageBaseURL + env.ContainerNames.WebDocuments + '/' + env.Blobs.ClientArticle"
             target="_blank"
             class="harmony-tertiary-link fw-bold">
            article</a>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="client-images">
          <img class="client-image image-1" src="../../../../assets/shapes/purple/hex-sm.svg" alt=""/>
          <img class="client-image image-2" src="../../../../assets/shapes/teal/short-hex-horizontal.svg" alt=""/>
          <img class="client-image image-3" src="../../../../assets/images/shaped/long-horizontal-people-6.png" alt=""/>
        </div>
      </div>
    </div>
  </div>
  <div class="client-location-box">
    <div class="client-location-info">
      <img class="client-location-icon" src="../../../../assets/icons/color/teal/icon-sm-location.svg" alt=""/>
      <p class="harmony-secondary-text">
        To find the solution that meets your needs, please contact a Harmony member company in a
        <a (click)="findNearbyClient()" class="harmony-tertiary-text fw-bold pointer">location near you</a> to discuss how we can help.
        Our member will use the support services of Harmony to offer the best possible solution.
      </p>
    </div>
  </div>
</div>
