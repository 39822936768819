<div class="member-profile-wrapper">
  <div class="row" *ngIf="company">
    <div class="col-12 col-xl-10 offset-xl-1">
      <div class="row">
        <div class="col-12 text-center text-center align-self-center">
          <h1 class="member-name">{{ company.Name}}</h1>
        </div>
      </div>
        <div class="row mb-1" *ngIf="company.IsMovingServiceProvider || company.IsRelocationServiceProvider">
            <div class="col-12 text-center">
                <b class="harmony-secondary-text">
                    {{ company.IsMovingServiceProvider ? 'Moving' : '' }}
                    {{ company.IsMovingServiceProvider && company.IsRelocationServiceProvider ? ' & ' : ''}}
                    {{ company.IsRelocationServiceProvider ? 'Relocation' : '' }}
                    services provider
                </b>
            </div>
        </div>
      <div class="row mt-3">
        <div class="col-12 col-xl-7">
          <div class="member-picture-wrapper align-self-center">
            <div class="member-picture-clip"
                 [ngStyle]="{'background-image': 'url(\'' + getCompanyPicture() + '\')' }">
            </div>
            <div class="proud-member">
              <img *ngIf="company.ProudMemberLogo !== null && company.ProudMemberLogo.length > 1"
                   [src]="company?.ProudMemberLogo"
                   class="proud-member-logo"
                   [alt]="company?.Name">
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-5 align-self-center text-center">
          <img [src]="getCompanyLogo()"
               class="member-logo"
               [alt]="company?.Name">
        </div>
      </div>
      <div class="row" style="margin-top: -2em">
        <div class="col-12 col-xl-7">
          <!-- About -->
          <div class="company-profile-wrapper" *ngIf="company.Biography && company.Biography.trim().length > 0">
            <h3>
              About {{ company.Name }}
            </h3>
            <p [innerText]="company?.Biography"></p>
          </div>
          <!-- Map -->
          <div class="member-map-wrapper">
            <app-member-map [CompanyData]="mapData"
                            id="map"
                            [Zoom]="4"
                            [MinZoom]="4"
                            [Center]="{ lat: company.Latitude!, lng: company.Longitude! }"
                            [MapStyle]="mapStyles.MemberMap">
            </app-member-map>
          </div>
          <div class="row mt-2">
            <div class="col-12 fw-bold">
              <div class="d-inline mx-2 harmony-primary-text"
                   *ngIf="!company.IsRelocationServiceProvider">
                <img width="25" height="25"
                     ngSrc="../../../assets/icons/map/map-marker-default.svg"
                     alt="moving-services">
                Moving Services
              </div>
              <div class="d-inline mx-2 harmony-tertiary-text"
                   *ngIf="!company.IsMovingServiceProvider && company.IsRelocationServiceProvider">
                <img width="25" height="25"
                     ngSrc="../../../assets/icons/map/map-marker-relocation.svg"
                     alt="relocation-services">
                Relocation Services
              </div>
              <div class="d-inline mx-2 harmony-accent-yellow-text"
                   *ngIf="company.IsMovingServiceProvider && company.IsRelocationServiceProvider">
                <img width="25" height="25"
                     ngSrc="../../../assets/icons/map/map-marker-all.svg"
                     alt="all-services">
                Moving and Relocation Services
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-5">
          <!-- Location & Contact -->
          <div class="member-contact-wrapper">
            <div class="row harmony-secondary-text">
              <div class="col-6">
                <h4 class="harmony-secondary-text mb-1 fw-bold">
                  Office location
                </h4>
                <p class="fw-bold mb-0">
                  {{ company.Name }}
                </p>
                <p class="mb-0">
                  {{ company.AddressPhysical?.length > 0 ? company.AddressPhysical?.trim() : '-' }}{{ company.AddressPhysical2?.length > 0 ? ', ' + company.AddressPhysical2?.trim() : '' }}
                </p>
                <p class="mb-0">
                  {{ company.CityPhysical?.length > 0 ? company.CityPhysical : '' }}{{ company.ZipCodePhysical?.length > 0 ? ', ' + company.ZipCodePhysical : '' }}
                </p>
                <p class="mb-0">
                  {{ company.CountryPhysical?.length > 0 ? company.CountryPhysical : ''}} {{ company.CountryPhysicalIso ? '(' + company.CountryPhysicalIso + ')' : '' }}
                </p>
              </div>
              <div class="col-6">
                <div class="row" *ngIf="companyTelephone || companyUrl">
                  <div class="col-12">
                    <h4 class="harmony-secondary-text mb-1 fw-bold">
                      Contact details
                    </h4>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 overflow-hidden">
                    <a *ngIf="companyTelephone && companyTelephone.PropertyValue"
                       href="tel:{{ companyTelephone.PropertyValue }}"
                       class="harmony-secondary-link">
                      {{ companyTelephone.PropertyValue }}
                    </a>
                    <br/>
                    <span *ngIf="companyUrl && companyUrl.PropertyValue"
                          (click)="openNewWindow(companyUrl.PropertyValue)"
                          class="harmony-secondary-link">
                      {{ companyUrl.PropertyValue.replace(' ', '') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4 col-compress">
                <button class="btn btn-sm btn-primary harmony-secondary-button w-100 text-white"
                        [disabled]="companyEmail?.PropertyValue == null"
                        (click)="contactMember()">Contact</button>
              </div>
              <div class="col-4 col-compress">
                <button class="btn btn-sm btn-primary harmony-accent-coral-button w-100 text-white text-nowrap"
                        (click)="getDirectQuote()">Direct quote</button>
              </div>
              <div class="col-4 col-compress">
                <button class="btn btn-sm btn-primary harmony-secondary-button w-100 text-white"
                        [disabled]="companyUrl?.PropertyValue == null"
                        (click)="openNewWindow(companyUrl?.PropertyValue)">website</button>
              </div>
            </div>
          </div>
          <!-- Latest Articles -->
          <div class="company-article-wrapper" style="margin-right: 2%">
              <ng-container *ngIf="article1">
                  <app-article-item [Article]="article1" [Class]="'headline-odd'"></app-article-item>
              </ng-container>
          </div>
          <div class="company-article-wrapper" style="margin-left: 2%">
              <ng-container *ngIf="article2">
                  <app-article-item [Article]="article2" [Class]="'headline-odd'"></app-article-item>
              </ng-container>
          </div>
          <div class="company-article-wrapper" *ngIf="!article1">
            <h5 class="text-center harmony-accent-grey-text">
              No recent articles...
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5" *ngIf="loading">
    <div class="col-12 text-center">
      <app-loader></app-loader>
    </div>
  </div>
  <div class="row mt-5 mb-5" *ngIf="notFound">
    <div class="col-12 text-center">
      <h1 class="harmony-tertiary-text fw-bold">
        Member not found...
      </h1>
    </div>
  </div>
</div>
