<div class="cookie-policy-wrapper">
  <div class="row">
    <div class="col-sm-12 col-md-10 offset-md-1">
      <div class="row">
        <div class="col-md-12">
          <h1 class="harmony-secondary-text fw-bold">
            Cookie Policy
          </h1>
        </div>
      </div>
      <br>
      <div class="row mb-2">
        <div class="col-md-12">
          <h4 class="harmony-primary-text fw-bold">
            1. What are cookies?
          </h4>
          <p class="harmony-accent-grey-text">
            A cookie is a small text file that a website stores on your computer or mobile device when you visit the site.
          </p>
          <ul>
            <li class="harmony-accent-grey-text">
              First party cookies are cookies set by the website you’re visiting. Only that website can read them.
              In addition, a website might potentially use external services, which also set their own cookies,
              known as third-party cookies.
            </li>
            <li class="harmony-accent-grey-text">
              Persistent cookies are cookies saved on your computer and that are not deleted automatically when you quit
              your browser, unlike a session cookie, which is deleted when you quit your browser.
            </li>
          </ul>
          <p class="harmony-accent-grey-text">
            Every time you visit Harmony’s website, you will be prompted to accept cookies. <br>
            The purpose is to enable the site to remember your preferences for a certain period of time. <br>
            That way, you don’t have to re-enter them when browsing around the site during the same visit.
          </p>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <h4 class="harmony-primary-text fw-bold">
            2.	How do we use cookies?
          </h4>
          <p class="harmony-accent-grey-text">
            Harmony’s website mostly uses “first-party cookies”. These are cookies set and controlled by Harmony,
            not by any external organisation.
            <br>
            However, to view some of our pages, you might have to accept cookies from external organisations.
            <br>
            The 3 types of first-party cookie we use are to:
          </p>
          <ul>
            <li class="harmony-accent-grey-text">
              store visitor preferences
            </li>
            <li class="harmony-accent-grey-text">
              make our websites operational
            </li>
          </ul>
          <p class="harmony-accent-grey-text">
            <b class="harmony-secondary-text">Visitor preferences</b>
            <br>
            These are set by us and only we can read them. They remember:
            <br>
          </p>
          <ul>
            <li class="harmony-accent-grey-text">
              if you have agreed to (or refused) this site’s cookie policy
            </li>
          </ul>
          <p class="harmony-accent-grey-text">
            <b class="harmony-secondary-text">Operational cookies</b>
            <br>
            There are some cookies that we have to include in order for certain web pages to function.
            For this reason, they do not require your consent. In particular:
          </p>
          <ul>
            <li class="harmony-accent-grey-text">
              authentication cookies
            </li>
            <li class="harmony-accent-grey-text">
              technical cookies required by certain IT systems
            </li>
          </ul>
          <p class="harmony-accent-grey-text">
            <b class="harmony-secondary-text">Authentication cookies</b>
            <br>
            These are stored when you log in to a Harmony site, using our authentication service.
            When you do this, you accept the associated privacy policy.
          </p>
        </div>
      </div>
      <div class="row mb-2 harmony-accent-grey-text">
        <div class="col-md-12">
          <h4 class="harmony-primary-text fw-bold">
            3.	Third-party cookies
          </h4>
          <p>
            Some of our pages display content from external providers, e.g. Google Maps.
            <br>
            To view this third-party content, you first have to accept their specific terms and conditions. This includes their cookie policies, which we have no control over.
            <br>
            But if you do not view this content, no third-party cookies are installed on your device.
          </p>
          <p>
            Third-party providers on the Harmony website:
          </p>
          <p>
            <b class="harmony-secondary-text">Google Maps</b>
            <br>
            <b class="harmony-secondary-text">LinkedIn</b>
          </p>
        </div>
      </div>
      <div class="row mb-5 harmony-accent-grey-text">
        <div class="col-md-12">
          <h4 class="harmony-primary-text fw-bold">
            4.	How can you manage cookies?
          </h4>
          <p>
            You can manage/delete cookies as you wish - for details, see
            <a href="https://www.aboutcookies.org" target="_blank" class="harmony-secondary-link">aboutcookies.org</a>.
          </p>
          <b class="harmony-secondary-text">Removing cookies from your device</b>
          <p>
            You can delete all cookies that are already on your device by clearing the browsing history of your browser. This will remove all cookies from all websites you have visited.
            <br>
            Be aware though that you may also lose some saved information (e.g. saved login details, site preferences).
          </p>
          <b class="harmony-secondary-text">Managing site-specific cookies</b>
          <p>
            For more detailed control over site-specific cookies, check the privacy and cookie settings in your preferred browser
          </p>
          <b class="harmony-secondary-text">Blocking cookies</b>
          <p>
            You can set most modern browsers to prevent any cookies being placed on your device,
            but you may then have to manually adjust some preferences every time you visit a site/page.
            <br>
            And some services and functionalities may not work properly at all (e.g. profile logging-in).
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
