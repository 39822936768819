<div class="pos-f-t">
  <nav class="navbar navbar-light bg-light px-2">
    <a class="corporate-header-logo text-dark justify-content-start" [routerLink]="['/']">
      <img src="../../../assets/branding/logo/HRN_logo_NoBackground.png" width="100" alt="logo"/>
    </a>
    <button class="navbar-toggler justify-content-end" type="button" data-bs-toggle="collapse" data-bs-target="#mobile-navbar" aria-controls="mobile-navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon harmony-primary-text"></span>
    </button>
  </nav>
  <div class="collapse" id="mobile-navbar">
    <div class="bg-light p-4">
        <!-- fa-icon needs to be corrected, it does not fit inside the search bar -->
        <ul class="list-unstyled">
          <li class="pt-2">
            <app-search-mobile></app-search-mobile>
          </li>
          <li class="pt-2">
            <a class="p-1 harmony-accent-grey-text secondary-link" [routerLink]="['']">
              Home
            </a>
          </li>
          <li class="pt-2">
            <div class="dropdown">
              <button class="btn dropdown-toggle header-mobile-btn harmony-accent-grey-text secondary-link" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                About us
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <button class="dropdown-item harmony-accent-grey-text secondary-link" type="button" [routerLink]="['/about-us/history']">History</button>
                <button class="dropdown-item harmony-accent-grey-text secondary-link" type="button" [routerLink]="['/about-us/clients']">Clients</button>
                <button class="dropdown-item harmony-accent-grey-text secondary-link" type="button" [routerLink]="['/about-us/services']">Services</button>
                <button class="dropdown-item harmony-accent-grey-text secondary-link" type="button" [routerLink]="['/about-us/people']">People</button>
                <button class="dropdown-item harmony-accent-grey-text secondary-link" type="button" [routerLink]="['/about-us/commitment']">Commitment</button>
              </div>
            </div>
          </li>
          <li class="pt-2">
            <a class="p-1 harmony-accent-grey-text secondary-link" [routerLink]="['/global-network']">
              Global network
            </a>
          </li>
          <li class="pt-2">
            <a class="p-1 harmony-accent-grey-text secondary-link" [routerLink]="['/insights']">
              News & Insights
            </a>
          </li>
          <li class="pt-2">
            <a class="p-1 harmony-accent-grey-text secondary-link" [routerLink]="['/joining-our-network']">
              Joining our network
            </a>
          </li>
          <li class="pt-2">
            <a class="p-1 harmony-accent-grey-text secondary-link" [routerLink]="['/contact']">
              Contact
            </a>
          </li>
          <li class="pt-2">
            <a class="btn btn-sm harmony-accent-coral-button text-white" [routerLink]="['/direct-quote']">Direct quote</a>
          </li>
        </ul>
    </div>
  </div>
</div>
