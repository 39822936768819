<div class="direct-quote-wrapper">
  <div class="row">
    <div class="col-sm-12 offset-sm-0 col-lg-10 offset-lg-1">
      <div class="row">
        <div class="col-12">
          <div class="quote-header">
            <div class="row">
              <div class="col-12 col-sm-3">
                <img class="image-1" src="../../../assets/images/vector/laptop-1.svg" alt="">
              </div>
              <div class="col-12 col-sm-9 p-2 p-lg-4">
                <h2 class="harmony-secondary-text fw-bold">Request a quote</h2>
                <p class="harmony-accent-grey-text">
                  Enter your quote details and inventory summary below.
                  <br>
                  You will be contacted with your personalised quote within 2 working days.
                </p>
              </div>
            </div>
          </div>
          <div class="quote-header-image">
            <img class="header-image" src="../../../assets/images/vector/combo-shape-1.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <form [formGroup]="quoteRequest">
            <div class="row">
              <!-- SECTION 1: What kind of service -->
              <div class="col-12 mt-2 col-lg-4 mt-lg-0">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="harmony-primary-text">Step 1:<b class="harmony-tertiary-text fs-4"> What services are you looking for?</b></h5>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <div class="form-check"
                         [ngClass]="{'required-checkbox': quoteRequest.controls['SelectedServices'].invalid && quoteRequest.controls['SelectedServices'].touched}">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="movingService"
                        [checked]="quoteRequest.controls['SelectedServices'].value.toString().includes('Moving Services')"
                        (change)="toggleService('Moving Services')">
                      <label class="form-check-label harmony-accent-grey-text" for="movingService">
                        Moving services
                      </label>
                    </div>
                    <div class="form-check mt-1"
                         [ngClass]="{'required-checkbox': quoteRequest.controls['SelectedServices'].invalid && quoteRequest.controls['SelectedServices'].touched}">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="relocationSupport"
                        [checked]="quoteRequest.controls['SelectedServices'].value.toString().includes('Relocation Support / Destination Services')"
                        (change)="toggleService('Relocation Support / Destination Services')">
                      <label class="form-check-label harmony-accent-grey-text" for="relocationSupport">
                        Relocation support services
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label for="reqServices" class="fw-bold harmony-secondary-text">
                      Description of requested Services
                    </label>
                    <textarea
                      id="reqServices"
                      class="form-control form-control-sm"
                      rows="4"
                      [formControlName]="'ServiceDescription'"
                      [ngClass]="{'is-invalid': quoteRequest.controls['ServiceDescription'].invalid && quoteRequest.controls['ServiceDescription'].touched}"
                      placeholder="Write any aspects that we would need to know to ensure an accurate quote"></textarea>
                  </div>
                </div>
              </div>
              <!-- SECTION 2: Moving Details -->
              <div class="col-12 mt-5 col-lg-4 mt-lg-0">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="harmony-primary-text">Step 2:<b class="harmony-tertiary-text fs-4"> Moving details</b></h5>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <label class="fw-bold harmony-secondary-text">When do you expect to move?</label>
                    <app-date-picker [identifier]="'moveDate'" (onChange)="setExpectedMoveDate($event)"
                                     [minDate]="getToday()"
                                     [showError]="quoteRequest.controls['ExpectedMoveDate'].invalid && quoteRequest.controls['ExpectedMoveDate'].touched"
                                     [placeholder]="'Select a date'"></app-date-picker>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label class="fw-bold harmony-secondary-text">Where are you moving from?</label>
                    <app-city-search
                      class="d-inline"
                      #origin
                      [Identifier]="'DirectQuoteOrigin'"
                      [ShowError]="quoteRequest.controls['Origin'].invalid && quoteRequest.controls['Origin'].touched"
                      [ErrorColor]="'#dc3545'"
                      [SelectedID]="null"
                      [CityName]="null"
                      (click)="quoteRequest.controls['Origin'].markAsTouched()"
                      (OnSelectCityObject)="setOrigin($event!)">
                    </app-city-search>
                    <small class="harmony-accent-grey-text"
                           *ngIf="quoteRequest.controls['Origin'].invalid && quoteRequest.controls['Origin'].touched">
                      Please search and select an option from the list
                    </small>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <label for="map" class="fw-bold harmony-secondary-text">Our members in this area</label>
                  </div>
                </div>
                <div class="row direct-quote-map">
                  <div class="col-12">
                      <app-member-map [CompanyData]="filteredMembers"
                                      id="map"
                                      [Zoom]="2.5"
                                      [MinZoom]="2.5"
                                      [Center]="{ lat: this.originLatitude, lng: this.originLongitude }"
                                      [MapStyle]="mapStyles.DirectQuote"
                                      [CenterOnUser]="true"></app-member-map>
                  </div>
                </div>
                <div class="row mt-2 fw-bold">
                  <div class="col-6 harmony-primary-text">
                    <img width="25" height="25"
                         ngSrc="../../../assets/icons/map/map-marker-default.svg"
                         alt="moving-services">
                    Moving Services
                  </div>
                  <div class="col-6 harmony-tertiary-text">
                    <img width="25" height="25"
                         ngSrc="../../../assets/icons/map/map-marker-relocation.svg"
                         alt="relocation-services">
                    Relocation Services
                  </div>
                  <div class="col-12 harmony-accent-yellow-text">
                    <img width="25" height="25"
                         ngSrc="../../../assets/icons/map/map-marker-all.svg"
                         alt="all-services">
                    Moving and Relocation Services
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label class="fw-bold harmony-secondary-text">Where are you moving to?</label>
                    <app-city-search
                      class="d-inline"
                      #destination
                      [Identifier]="'DirectQuoteDestination'"
                      [ShowError]="quoteRequest.controls['Destination'].invalid && quoteRequest.controls['Destination'].touched"
                      [ErrorColor]="'#dc3545'"
                      [SelectedID]="null"
                      [CityName]="null"
                      (click)="quoteRequest.controls['Destination'].markAsTouched()"
                      (OnSelectCityObject)="setDestination($event)">
                    </app-city-search>
                    <small class="harmony-accent-grey-text"
                           *ngIf="quoteRequest.controls['Destination'].invalid && quoteRequest.controls['Destination'].touched">
                      Please search and select an option from the list
                    </small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <label for="selectMember" class="fw-bold harmony-secondary-text">
                      I want a quote from
                    </label>
                    <button class="form form-control form-control-sm dropdown-toggle text-start" type="button"
                            id="selectMember"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            [title]="'Search a city before selecting'"
                            [disabled]="!canViewMembers"
                            [ngClass]="{'is-invalid': quoteRequest.controls['QuoteCompany'].invalid && quoteRequest.controls['QuoteCompany'].touched}"
                            (click)="quoteRequest.controls['QuoteCompany'].markAsTouched()">
                      {{ dropdownText }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="selectMember">
                      <ng-container *ngFor="let m of filteredMembers">
                        <ul class="dropdown-item pointer-plain" (click)="setMember(m)">
                          <img *ngIf="m.IsMovingServiceProvider && !m.IsRelocationServiceProvider"
                               ngSrc="../../../assets/icons/map/map-marker-default.svg"
                               width="20" height="20" alt="">
                          <img *ngIf="m.IsRelocationServiceProvider && !m.IsMovingServiceProvider"
                               ngSrc="../../../assets/icons/map/map-marker-relocation.svg"
                               width="20" height="20" alt="">
                          <img *ngIf="m.IsMovingServiceProvider && m.IsRelocationServiceProvider"
                               ngSrc="../../../assets/icons/map/map-marker-all.svg"
                               width="20" height="20" alt="">
                          {{ m.Name }}, {{ m.Country }}
                        </ul>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SECTION 3: Contact details -->
              <div class="col-12 mt-5 col-lg-4 mt-lg-0">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="harmony-primary-text">Step 3:<b class="harmony-tertiary-text fs-4"> Contact details</b></h5>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12">
                    <label class="fw-bold harmony-secondary-text">
                      Title
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="form-check form-check-inline"
                      (click)="quoteRequest.controls['Title'].setValue('Mr.')"
                      [ngClass]="{'required-checkbox': quoteRequest.controls['Title'].invalid && quoteRequest.controls['Title'].touched}">
                      <input class="form-check-input" type="radio" name="prefix" id="prefixMr" value="Mr.">
                      <label class="form-check-label harmony-accent-grey-text" for="prefixMr">
                        Mr.
                      </label>
                    </div>
                    <div class="form-check form-check-inline"
                         (click)="quoteRequest.controls['Title'].setValue('Ms.')"
                         [ngClass]="{'required-checkbox': quoteRequest.controls['Title'].invalid && quoteRequest.controls['Title'].touched}">
                      <input class="form-check-input" type="radio" name="prefix" id="prefixMs" value="Ms.">
                      <label class="form-check-label harmony-accent-grey-text" for="prefixMs">
                        Ms.
                      </label>
                    </div>
                    <div class="form-check form-check-inline"
                         (click)="quoteRequest.controls['Title'].setValue('Mx.')"
                         [ngClass]="{'required-checkbox': quoteRequest.controls['Title'].invalid && quoteRequest.controls['Title'].touched}">
                      <input class="form-check-input" type="radio" name="prefix" id="prefixMx" value="Mx.">
                      <label class="form-check-label harmony-accent-grey-text" for="prefixMx">
                        Mx.
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label for="firstName" class="fw-bold harmony-secondary-text">
                      First name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      class="form-control form-control-sm"
                      placeholder="Enter your first name"
                      [ngClass]="{'is-invalid': quoteRequest.controls['FirstName'].invalid && quoteRequest.controls['FirstName'].touched}"
                      [formControlName]="'FirstName'"/>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label for="lastName" class="fw-bold harmony-secondary-text">
                      Last name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      class="form-control form-control-sm"
                      placeholder="Enter your last name"
                      [ngClass]="{'is-invalid': quoteRequest.controls['LastName'].invalid && quoteRequest.controls['LastName'].touched}"
                      [formControlName]="'LastName'"/>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label for="email" class="fw-bold harmony-secondary-text">
                      Email
                    </label>
                    <input
                      type="email"
                      [email]="true"
                      id="email"
                      class="form-control form-control-sm"
                      placeholder="Enter your email"
                      [ngClass]="{'is-invalid': quoteRequest.controls['Email'].invalid && quoteRequest.controls['Email'].touched}"
                      [formControlName]="'Email'"/>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label for="phoneNumber" class="fw-bold harmony-secondary-text">
                      Phone number
                    </label>
                    <input
                      type="text"
                      id="phoneNumber"
                      class="form-control form-control-sm"
                      placeholder="Enter your phone number"
                      [ngClass]="{'is-invalid': quoteRequest.controls['ContactNumber'].invalid && quoteRequest.controls['ContactNumber'].touched}"
                      [formControlName]="'ContactNumber'"/>
                    <small *ngIf="quoteRequest.get('ContactNumber')?.hasError('pattern')" class="harmony-tertiary-text">invalid
                      contact number!</small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="form-check"
                         [ngClass]="{'required-checkbox': quoteRequest.controls['AcceptedTerms'].invalid && quoteRequest.controls['AcceptedTerms'].touched}">
                      <input
                        type="checkbox"
                        id="acceptTerms"
                        class="form-check-input"
                        [formControlName]="'AcceptedTerms'">
                      <label class="form-check-label harmony-accent-grey-text" for="acceptTerms">
                        <small>
                          I understand and agree my personal data is only used to contact me regarding this
                          quote. For more information about the use of your personal data please review our
                          <a [routerLink]="['/policies/privacy']"
                             target="_blank"
                             class="harmony-secondary-link fw-bold">privacy notice</a>.
                        </small>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12" *ngIf="reCaptchaSiteKey">
                    <div [ngClass]="{'required-checkbox': !this.recaptchaApproved && quoteRequest.touched}">
                      <re-captcha (resolved)="resolved($event)" siteKey="{{reCaptchaSiteKey}}"></re-captcha>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <button
                      class="btn btn-primary harmony-accent-coral-button w-100"
                      (click)="sendQuoteRequest()">
                      Submit Request
                    </button>
                    <small class="harmony-accent-grey-text"
                           [ngClass]="{'required-text': quoteRequest.invalid && quoteRequest.touched}">
                      All fields are mandatory
                    </small>
                    <app-loader *ngIf="loading"></app-loader>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
