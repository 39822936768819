<div class="modal-body">
  <div class="row">
    <div class="col-12" style="white-space: pre-line">
      {{Message}}
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer">
  <button type="button" class="btn btn-primary harmony-tertiary-button" (click)="confirm()">{{ConfirmButton}}</button>
  <button type="button" class="btn btn-primary harmony-primary-button" *ngIf="CancelButton && CancelButton.length > 0" (click)="cancel()">{{CancelButton}}</button>
</div>

